import { HighlightCylinder, MyLoaders, PlayerObject, PositionalHint, RaveNavigation } from "@ravespaceio/rave-engine"
import * as THREE from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { getEngine } from "~/space/engine";
import { dekaBotPositions, useGameStore } from "~/store/game";
import { setupEnergyCells } from "./energycells";
import { create3DUi } from "~/space/_lib/utils/3dui";
import { useSpaceStore } from "~/store/space";
import { SphereCollider } from "@ravespaceio/rave-engine/build/engine/src/helper/interaction/SimpleColliderSystem";
import { setupAlwaysPlayerFacing } from "~/space/_lib/shader/billboard";
import { getSpace } from "~/space/space";
import { gsap, TweenMax, TweenLite, TimelineLite, Power3, Power2, Power1, Circ, Linear } from 'gsap'
import { getProjectEnvMap } from "~/space/_lib/mechanic/textures";
import { PlayerCharacter_EntityM } from "@ravespaceio/rave-engine/build/engine/src/engine/player/Object/PlayerCharacter/PlayerCharacter_EntityM";
import { disableFrustum } from "~/space/_lib/utils/three";

export async function setupDekaBot() {


	const engine = getEngine()
	const space = getSpace()
	const gameStore = useGameStore()
	const spaceStore = useSpaceStore()



	// Platforms
	space.loader.addFile('/dek/models/game/platform.glb')
	space.loader.onceAlready("loaded", () => {
		const gltf = space.loader.getLoaded("/dek/models/game/platform.glb")
		Object.values(dekaBotPositions).forEach(position => {
			const model = gltf.scene.clone() as THREE.Group
			model.position.copy(position)
			model.position.y -= 0.3
			engine.scene.getObjectByName("ModelGroup").add(model);
		});

	})

	// DEKABOT YOUNG
	const dekabot_young_glb = await MyLoaders.gltfLoader.loadAsync('/dek/models/game/dekabot_young.glb') as GLTF
	const dekabot_young = dekabot_young_glb.scene
	// dekabot_young.scale.set(10,10,10)
	//@ts-ignore
	dekabot_young.children[0].children[1].material.envMap = getProjectEnvMap()

	engine.loop.register((dt) => {
		const t = performance.now() / 1000
		dekabot_young.position.y = (Math.sin((t + 1) * 1) + 1) * 0.1
	})

	const ui = create3DUi("dekabot", { x: 0, y: 2, z: 0 }, "", 2, 1)
	ui.name = "UIDEKABOT"
	space.gui.addToSceneFolder(ui)

	const dekabot_young_group = new THREE.Group()
	dekabot_young_group.name = "DEKABOT YOUNG"
	dekabot_young_group.add(dekabot_young)
	dekabot_young_group.add(ui)
	engine.scene.add(dekabot_young_group)
	dekabot_young_group.position.set(1, 2.8, 0)
	dekabot_young_group.rotation.y = 2.2
	space.gui.addToSceneFolder(dekabot_young_group)
	gameStore.dekabotGroup = dekabot_young_group
	disableFrustum(dekabot_young)


	/* ANIMATIONS */
	const mixer = new THREE.AnimationMixer(dekabot_young);
	const clips = dekabot_young_glb.animations;
	engine.loop.register((dt) => {
		mixer.update(dt);
	})
	const idleClip = THREE.AnimationClip.findByName(clips, "Idle");
	const idle = mixer.clipAction(idleClip);
	const waveClip = THREE.AnimationClip.findByName(clips, "Wave");
	const wave = mixer.clipAction(waveClip);
	const flyingClip = THREE.AnimationClip.findByName(clips, "Flying");
	const flying = mixer.clipAction(flyingClip);

	idle.play();


	/* AUDIO */

	// var dekabotSound: THREE.PositionalAudio
	// engine.audioManager.setupPromise.then(() => {
	// 	dekabotSound = new THREE.PositionalAudio(engine.audioManager.listener!)
	// 	MyLoaders.audioLoader.loadAsync('/dek/audio/dekabot/2.mp3').then((buffer) => {
	// 		dekabotSound.setBuffer(buffer);
	// 		dekabotSound.setRefDistance(2)
	// 		dekabotSound.setLoop(false)
	// 		dekabotSound.setVolume(0.6);
	// 		dekabotSound.position.set(0, 0, 0)
	// 		dekabot_young_group.add(dekabotSound)
	// 		engine.audioManager.registerMedia(dekabotSound)
	// 	})
	// })


	/* FLYING */

	watch(() => gameStore.dekaBotPosition, (position) => {
		if (gameStore.questStage > 0) {
			flying.play()
			const tl = new TimelineLite({
				onComplete: () => {
					flying.stop()
				}
			});
			tl.to(dekabot_young.rotation, 1, { x: 1.2, ease: Power2.easeInOut });
			tl.to(dekabot_young_group.position, 3, { x: gameStore.dekaBotPosition.x, y: gameStore.dekaBotPosition.y + 4, z: gameStore.dekaBotPosition.z, ease: Power2.easeInOut }, "-=1");
			tl.to(dekabot_young.rotation, 1, { x: 0, ease: Power2.easeInOut }, "-=1");
			tl.to(dekabot_young_group.position, 2, { y: gameStore.dekaBotPosition.y, ease: Power2.easeInOut }, "-=1");
		}
	}, { immediate: true })


	space.eventbus.onAlready("startIntro", (data: any) => {
		// ui.visible = false
		ui.position.x = -10
		flying.play()
		const tl = new TimelineLite({
			onComplete: () => {
				flying.stop()
				idle.stop()
				wave.play()
				setTimeout(() => {
					wave.stop()
					idle.play()
				}, 3000);
				spaceStore.showIntroState = true
			}
		});
		tl.to(dekabot_young.rotation, 1, { x: 1.2, ease: Power2.easeInOut });
		tl.to(dekabot_young_group.position, 4, { x: dekaBotPositions.start.x, y: dekaBotPositions.start.y + 1.1, z: dekaBotPositions.start.z, ease: Power2.easeInOut }, "-=1");
		tl.to(dekabot_young.rotation, 1, { x: 0, ease: Power2.easeInOut }, "-=1");
		tl.to(dekabot_young_group.rotation, 1, { y: 1.6, ease: Power2.easeInOut }, "-=1");
		tl.to(dekabot_young_group.position, 2, { y: dekaBotPositions.start.y, ease: Power2.easeInOut }, "-=1");
		tl.to(ui.position, 3, { x: 0, ease: Power2.easeInOut }, "-=2.5");
		tl.add(function () {

		})
	})



	/* SHOW QUEST HINT WHEN STAGE 0 */
	let playerfaced = false
	watch(() => gameStore.questStage, (stage) => {
		if (stage > 0 && !playerfaced) {
			setupAlwaysPlayerFacing(dekabot_young_group)
			playerfaced = true
		}
		resetDekaBotText()
	}, { immediate: true })



	const hc = new HighlightCylinder()
	engine.scene.add(hc)
	hc.scale.set(0.5, 1, 0.5)
	hc.position.set(21, 6.6, -11.93)
	hc.visible = false

	space.pciSystem.add({
		collider: new SphereCollider(hc, 3),
		onColliderEnter: () => {
			if (gameStore.questStage > 0) {
				resetDekaBotText()
				if (gameStore.dekaBotPosition != dekaBotPositions.start) {
					gameStore.dekaBotPosition = dekaBotPositions.start
				}
			}
		}
	})

	let isVr = false
	getSpace().browser.isVR.then(isVR => { if (isVR) { isVR = true } })
	watch(() => spaceStore.onboardingDone, () => {
		if (gameStore.questStage == 0 && !isVr) {
			setTimeout(() => {
				dekaBotIntro()
			}, 1000);
		}
	}, { immediate: true })



	/* HR POSITION */
	const hrposition = new THREE.Object3D()
	hrposition.position.set(-0.2, 0.83, -18.8)
	space.pciSystem.add({
		collider: new SphereCollider(hrposition, 3),
		onColliderEnter: () => {
			gameStore.dekaBotText = "Hast du schon den perfekten Fachbereich für dich gefunden?"
			if (gameStore.dekaBotPosition != dekaBotPositions.hr) {
				gameStore.dekaBotPosition = dekaBotPositions.hr
			}
		}
	})

	/* Elevator POSITION */
	const elevatorPosition = new THREE.Object3D()
	elevatorPosition.position.set(-8.1, 1, 1.51)
	space.pciSystem.add({
		collider: new SphereCollider(elevatorPosition, 4),
		onColliderEnter: () => {
			gameStore.dekaBotText = "Nutze den Fahrstuhl, um zum Cinema zu kommen."
			if (gameStore.dekaBotPosition != dekaBotPositions.aufzug) {
				gameStore.dekaBotPosition = dekaBotPositions.aufzug
			}
		}
	})

	/* STAGE POSITION */
	const stagePosition = new THREE.Object3D()
	stagePosition.position.set(1.25, 1, 15.16)
	space.pciSystem.add({
		collider: new SphereCollider(stagePosition, 4),
		onColliderEnter: () => {
			gameStore.dekaBotText = "Freu dich auf spannende Vorträge!"
			if (gameStore.dekaBotPosition != dekaBotPositions.stage) {
				gameStore.dekaBotPosition = dekaBotPositions.stage
			}
		}
	})


}



export function resetDekaBot() {
	const gameStore = useGameStore()
	// setTimeout(() => {
	// 	resetDekaBotText(gameStore.questStage)
	// }, 3000);
	// gameStore.dekaBotPosition = { x: 19, y: 7.3, z: -12.7 }
}
export function resetDekaBotText() {
	const gameStore = useGameStore()

	if (gameStore.questStage == 0) {
		gameStore.dekaBotText = "Hi, bitte bringe mir alle Energiezellen für meinen Kumpel. Du wirst auch belohnt!"
	}
	if (gameStore.questStage == 1) {
		gameStore.dekaBotText = "Danke, dass du dich auf die Suche nach den Energiezellen machst."
	}
	if (gameStore.questStage == 2) {
		gameStore.dekaBotText = "Du hast alle Energiezellen gefunden! Bringe sie nun zu meinem Freund."
	}
	if (gameStore.questStage == 3) {
		gameStore.dekaBotText = "Wohoo! Das hast du super gemacht. Danke!"
	}
}



function handleSpacebar(event) {
	const gameStore = useGameStore()
	if (event.key === ' ' || event.keyCode === 32) {
		gameStore.introState++
	}
}



export function dekaBotIntro() {
	const gameStore = useGameStore()
	const engine = getEngine()
	const space = getSpace()
	engine.multiplayer.netPlayerManager.netPlayerGroup.visible = false

	watch(() => gameStore.introState, (state) => {
		continueIntro()

	}, { immediate: true })

	const introCam = new THREE.PerspectiveCamera()
	introCam.name = "MiniintroCamera"
	introCam.lookAt(dekaBotPositions.start)
	introCam.position.set(29, 8.1, -11.6)
	introCam.rotation.set(0, -4.7, 0)
	gameStore.introCam = introCam

	const tl = new TimelineLite({
		onComplete: () => {
			window.addEventListener('keydown', handleSpacebar);
		}
	});

	if (space.browser.isMobile) {
		tl.to(introCam.position, 6, { x: 27, ease: Power2.easeInOut });
	} else {
		tl.to(introCam.position, 6, { x: 25.5, ease: Power2.easeInOut });
	}

	space.gui.addToSceneFolder(introCam)
	engine.player.getLocalPlayer("playerCharacter").get<PlayerCharacter_EntityM>("entity_manager").changeEntity("empty_entity")
	engine.renderer.renderCamera = introCam
	introCam.updateProjectionMatrix()
	engine.renderer.resize()
	gameStore.dekaBotText = "Hey, ich bin Tiny! Willkommen zur Brown Bag Session im Dekaverse. Dieser Space ist im Rahmen des Deka Career Days entstanden."

	getSpace().eventbus.emit("startIntro")

}

export function continueIntro() {
	const gameStore = useGameStore()
	const spaceStore = useSpaceStore()
	const engine = getEngine()
	const space = getSpace()

	if (gameStore.introState == 1) {
		gameStore.dekaBotText = "Heute versorgen wir dich mit spannenden Infos zu unseren Projekten und diskutieren darüber, wie wir in Zukunft leben und arbeiten werden. Leider habe ich aber ein Problem!"
	}

	if (gameStore.introState == 2) {
		gameStore.dekaBotText = "Mein Freund Tally ist bei der Vorbereitung des Events eingeschlafen. Hilf mir bitte, den Faulpelz aus seinem Powernap aufzuwecken."

		if (!space.browser.isMobile) {
			const tl = new TimelineLite();
			tl.to(gameStore.introCam.position, 2, { x: 18.5, z: -16.4, ease: Power2.easeInOut });
			tl.to(gameStore.introCam.rotation, 2, { y: -3, ease: Power2.easeInOut }, "-=2");
			tl.to(gameStore.dekabotGroup.rotation, 2, { y: -2.48, ease: Power2.easeInOut }, "-=2");
		}

	}

	if (gameStore.introState == 3) {
		gameStore.dekaBotText = "Seine Energiezellen sind im Space verstreut. Erkunde den Space und sammle die Energie für Tally."

		if (!space.browser.isMobile) {
			const tl = new TimelineLite();
			tl.to(gameStore.introCam.position, 2, { x: 23.3, y: 8.3, z: -15.3, ease: Power2.easeInOut });
			tl.to(gameStore.introCam.rotation, 2, { y: -3.84, ease: Power2.easeInOut }, "-=2");
			tl.to(gameStore.dekabotGroup.rotation, 2, { y: -3.7, ease: Power2.easeInOut }, "-=2");
		}
	}


	if (gameStore.introState == 4) {
		gameStore.dekaBotText = "Als Belohnung habe ich ein besonderes Outfit für deinen Avatar."

		if (!space.browser.isMobile) {
			const tl = new TimelineLite();
			tl.to(gameStore.introCam.position, 2, { x: 23.4, z: -15.7, ease: Power2.easeInOut });
			tl.to(gameStore.introCam.rotation, 2, { y: -3.6, ease: Power2.easeInOut }, "-=2");
		}
	}

	// if (gameStore.introState == 5) {
	// 	if (getSpace().browser.isMobile) {
	// 		gameStore.dekaBotText = "Achso... bewegen kannst du dich mit dem Joystick. Nutze das Teleport-Menü um dich schneller zu teleportieren."

	// 	} else {
	// 		gameStore.dekaBotText = "Achso... bewegen kannst du dich mit WASD oder den Pfeiltasten, halte die linke Maustaste gedrückt, um dich umzusehen. Mit T kannst du dich Teleportieren und mit Umschalt bzw. Shift kannst du sprinten."

	// 	}
	// }

	if (gameStore.introState == 5) {
		gameStore.dekaBotText = "Jetzt aber genug gequatscht. Viel Freude bei unserer Brown Bag Session!"
	}

	if (gameStore.introState == 6) {
		window.removeEventListener('keydown', handleSpacebar);
		gameStore.questStage = 1
		engine.player.getLocalPlayer("playerCharacter").get<PlayerCharacter_EntityM>("entity_manager").changeEntity("physics_entity")
		engine.renderer.renderCamera = engine.player.camera
		spaceStore.showInterface = true
		engine.multiplayer.netPlayerManager.netPlayerGroup.visible = true;
		engine.renderer.resize()
		setTimeout(() => {
			spaceStore.showQuestModal = true
		}, 2000);
	}
}
